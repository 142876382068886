import React, { useState, useEffect } from 'react';

import '../css/index.css';

import Mine from '../img/mine.png';

import Tabs from '../components/tabs';
import SlotMachine from '../components/slotMachine';

function MainPage() {
    const [counter, setCounter] = useState(0);
    const [leaders, setLeaders] = useState([]);
    const telegramId = new URLSearchParams(window.location.search).get('telegram_id');

    const [circles, setCircles] = useState([]);

    useEffect(() => {
        // Инициализация Telegram WebApp API
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.ready();
            window.Telegram.WebApp.expand();
        }

        // Fetch counter data
        fetch(`/api/proxy/counter?telegram_id=${telegramId}`)
        .then(response => response.json())
        .then(data => {
            if (data.counter !== undefined) {
                setCounter(data.counter);
            } else {
                console.error("Error fetching counter:", data.error);
            }
        })
        .catch(error => console.error("Error fetching counter:", error));

        // Fetch leaders data
        fetch('/api/proxy/leaders')
        .then(response => response.json())
        .then(data => {
            if (Array.isArray(data)) {
                setLeaders(data);
            } else {
                console.error("Error fetching leaders:", data.error);
            }
        })
        .catch(error => console.error("Error fetching leaders:", error));
    }, [telegramId]);

    const incrementCounter = (event) => {
        const btn = event.currentTarget;
        const rect = btn.getBoundingClientRect();
        // Генерация случайной позиции по горизонтали внутри кнопки
        const randomX = rect.left + Math.random() * rect.width;
        const circleY = rect.top + 10; // Сверху кнопки, немного выше границы

        const newCircle = {
        id: Date.now(),
        left: randomX,
        top: circleY,
        };

        setCircles((prevCircles) => [...prevCircles, newCircle]);

        setTimeout(() => {
        setCircles((prevCircles) => prevCircles.filter((circle) => circle.id !== newCircle.id));
        }, 1500); // Удаляем кружочек после завершения анимации

        fetch('/api/proxy/increment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ telegram_id: telegramId })
        })
        .then(response => response.json())
        .then(data => {
            if (data.counter !== undefined) {
                setCounter(data.counter);
            } else {
                console.error("Error incrementing counter:", data.error);
            }
        })
        .catch(error => console.error("Error incrementing counter:", error));
    };

    // const loadLeaders = () => {
    //     fetch('/api/proxy/leaders')
    //         .then(response => response.json())
    //         .then(data => {
    //             if (Array.isArray(data)) {
    //                 setLeaders(data);
    //             } else {
    //                 console.error("Error fetching leaders:", data.error);
    //             }
    //         })
    //         .catch(error => console.error("Error fetching leaders:", error));
    // };

    const tabs = [
        {
          
          className: 'tabHome',
          content: (
            <div className="homeMain">
                {circles.map((circle) => (
                    <div
                        key={circle.id}
                        className="circle"
                        style={{ left: `${circle.left}px`, top: `${circle.top}px` }}
                    />
                ))}
              <button className="coin" onClick={incrementCounter}>&nbsp;</button>
              <div className=" balance">{counter}</div>
            </div>
          ),
        },
        {
          
          className: 'tabShop',
          content: (
            <div className="shopMain">
              <div className="shopItem">
                <img src={Mine} />
                <div className="shopItemInfo">
                  <div className="heading">Golden mine  lvl.1 </div>
                  <div className="shopItemUpgrade">
                    <button className="btn btnSecondary">upgrade</button>
                    <div className="shopItemProgress">
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shopItem">
                <img src={Mine} />
                <div className="shopItemInfo">
                  <div className="heading">Golden mine lvl.1 </div>
                  <div className="shopItemUpgrade">
                    <button className="btn btnSecondary">upgrade</button>
                    <div className="shopItemProgress">
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shopItem">
                <img src={Mine} />
                <div className="shopItemInfo">
                  <div className="heading">Golden mine lvl.1 </div>
                  <div className="shopItemUpgrade">
                    <button className="btn btnSecondary">upgrade</button>
                    <div className="shopItemProgress">
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shopItem">
                <img src={Mine} />
                <div className="shopItemInfo">
                  <div className="heading">Golden mine lvl.1 </div>
                  <div className="shopItemUpgrade">
                    <button className="btn btnSecondary">upgrade</button>
                    <div className="shopItemProgress">
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem active">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                      <div className="prorgressItem">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
        },
        {
          
          className: 'tabCasino',
    
          content: (
            <div className="casinoMain">
              <SlotMachine />
            </div>
          ),
        },
        {
         
          className: 'tabLeader',
    
          content: (
            <div className="leaderboardMain">
              <div className="heading">Leader Board</div>
              <div className="tableWrapper">
                <table>
                <tr>
                    <th>User Name</th>
                    <th>Rating</th>
                </tr>
                {leaders.map((leader, index) => (
                    <tr key={index}>
                        <td>{leader.telegram_id}</td>
                        <td>{leader.counter}</td>
                    </tr>
                ))}
                </table>
              </div>
            </div>
          ),
        },
        {
          
          className: 'tabWallet',
    
          content: (
            <div className="walletMain">
              <div className="heading">My game wallet token</div>
              <div className="card">
                <div className="cardNumber">aGV5BDFfgh4r5#$@#nfd</div>
                <button className="btn btnSecondary">copy</button>
              </div>
              <div className="heading">My balance</div>
              <div className="balanceInfo">
                <p id="counter">{counter}</p>
              </div>
              <button className="btn btnMain" >
                click to top up your balance
              </button>
            </div>
          ),
        },
      ];

    return (
        // <div>
        //     <h1>Counter: {counter}</h1>
        //     <button onClick={incrementCounter}>Increment</button>

        //     <button onClick={loadLeaders}>View Leaders</button>
        //     <ul>
        //         {leaders.map((leader, index) => (
        //             <li key={index}>User {leader.telegram_id}: {leader.counter}</li>
        //         ))}
        //     </ul>
        // </div>

        <div className="container">
            <Tabs tabs={tabs} />
        </div>
    );
}

export default MainPage;
