import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div style={styles.container}>
      <div style={styles.tabList}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${tab.className} ${activeTab === index ? 'active' : ''}`}
            style={{
              ...styles.tabButton
            }}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div style={styles.tabContent}>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    textAlign: 'center',
    
  },
  tabList: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  tabButton: {
    flex: 1,
    padding: '10px',
    cursor: 'pointer',
   
    outline: 'none',
    transition: 'background-color 0.3s',
  },
  tabContent: {
    height: '432px',
    width: '375px'
  }
};

export default Tabs;
