import React, { useState, useEffect } from 'react';

import Bell from '../img/bell.png';
import Clever from '../img/clever.png';
import Crystal from '../img/crystal.png';
import Grape from '../img/grape.png';
import Lemon from '../img/lemon.png';
import Mellon from '../img/mellon.png';
import Seven from '../img/seven.png';

const SlotMachine = () => {
  const [slots, setSlots] = useState([[], [], []]);
  const [chips, setChips] = useState(100);
  const [bet, setBet] = useState(1);
  const [spinning, setSpinning] = useState([false, false, false]);
  const [isSpinning, setIsSpinning] = useState(false);

  const images = [Bell, Clever, Crystal, Grape, Lemon, Mellon, Seven]; // Пути к изображениям для слотов

  useEffect(() => {
    setSlots([randomizeImages(), randomizeImages(), randomizeImages()]);
  }, []);

  const randomizeImages = () => {
    const randomImages = Array(15)
      .fill(null)
      .map(() => images[Math.floor(Math.random() * images.length)]);
    return [...randomImages, ...randomImages]; // Дублируем список для плавной прокрутки
  };

  const startSlots = () => {
    if (chips >= bet && !isSpinning) {
      setIsSpinning(true);
      setSpinning([true, true, true]);

      setTimeout(() => {
        const newSlots = [randomizeImages(), randomizeImages(), randomizeImages()];
        setSlots(newSlots);

        setSpinning([false, false, false]);
        setIsSpinning(false);

        if (newSlots[0][7] === newSlots[1][7] && newSlots[1][7] === newSlots[2][7]) {
          setChips(chips + bet * 10);
        } else {
          setChips(chips - bet);
        }
      }, 2000);
    } else if (chips < bet) {
      alert('Недостаточно фишек для ставки.');
    }
  };

  const increaseBet = () => {
    if (chips > bet && !isSpinning) {
      setBet(bet + 1);
    }
  };

  const decreaseBet = () => {
    if (bet > 1 && !isSpinning) {
      setBet(bet - 1);
    }
  };

  return (
    <div style={styles.container} className="slotMachine">
      <p className='slotChipsAmont'>{chips}</p>

      <div style={styles.slots} className="slotMachine_slots">
        {slots.map((slot, index) => (
          <div key={index} style={styles.slot}>
            <div
              style={{
                ...styles.slotInner,
                animation: spinning[index] ? 'spin 2.5s ease-in-out infinite' : 'none',
              }}>
              {slot.map((imgSrc, i) => (
                <div key={i} style={styles.number}>
                  <img src={imgSrc} alt={`slot ${i}`} style={styles.image} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="slotBtnHolder">
        <button className='slotStartBtn' onClick={startSlots} style={styles.startButton} disabled={isSpinning}>
          Spin
        </button>
        <div className="slotBetHolder">
          <button className='slotBetBtn' onClick={decreaseBet} style={styles.button} disabled={isSpinning}>
            -
          </button>
          <p className='betNum'>Bet: {bet}</p>

          <button className='slotBetBtn' onClick={increaseBet} style={styles.button} disabled={isSpinning}>
            +
          </button>
        </div>
      </div>

    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  slots: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  slot: {
    width: '50px',
    height: '131px',
    overflow: 'hidden',
    borderRadius: '5px',
    position: 'relative',
  },
  slotInner: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  number: {
    fontSize: '2em',
    width: '50px',
    height: '43px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '60%',
    height: '60%',
    objectFit: 'contain',
  },
};

export default SlotMachine;
